export default {
    googleAnalytics: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.googleAnalytics',
            errors: [],
        },
    },
    googleVerification: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.googleVerification',
            errors: [],
        },
        events: {
            change: function(data) {
                const isTag = data.indexOf('content="');
                if (isTag > 0) {
                    const contentStart = data.indexOf('content="') + 9;
                    let content = data.slice(contentStart);
                    const contentEnd = content.indexOf('"');
                    content = content.slice(0, contentEnd);
                    this.googleVerification.value = content;
                }
            },
        },
    },
    googleTagManager: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.googleTagManager',
            errors: [],
        },
    },
    metaPixel: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metaPixel',
            errors: [],
        },
    },
    yandexMetrika: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.yandexMetrika',
            errors: [],
        },
    },
    yandexVerification: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.yandexVerification',
            errors: [],
        },
        events: {
            change: function(data) {
                const isTag = data.indexOf('content="');
                if (isTag > 0) {
                    const contentStart = data.indexOf('content="') + 9;
                    let content = data.slice(contentStart);
                    const contentEnd = content.indexOf('"');
                    content = content.slice(0, contentEnd);
                    this.yandexVerification.value = content;
                }
            },
        },
    },
    snapchatPixel: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.snapchatPixel',
            errors: [],
        },
    },
    customCode: {
        component: 'textarea',
        props: {
            labelTrans: 'settings.seo.customCode.title',
            errors: [],
        },
    },
    callTrackingMango: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.callTrackingMango',
            errors: [],
        },
    },
    // customCodeFooter: {
    //     component: 'textarea',
    //     props: {
    //         labelTrans: 'settings.seo.customCodeFooter.title',
    //         errors: [],
    //     },
    // },
};
